.reset-pwd-container {
    background-color: #eaf7f9; /* Fond bleu clair */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    margin: 0;
}

.reset-pwd-container h2 {
    font-size: 30px;
    color: #242b5e;
}

.reset-pwd-form {
    max-width: 500px; /* Largeur maximale du formulaire */
    width: 100%;
    padding: 50px 40px; /* Espacement interne du formulaire */
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Ombre grise */
    text-align: center; /* Contenu centré à l'intérieur de la carte */
}

.reset-pwd-form input {
    width: calc(100% - 20px); /* Ajustement de la largeur pour permettre un padding */
    padding: 10px;
    margin: 20px 0px; /* Marge horizontale ajoutée pour centrer */
    border: 1px solid #ccc;
    border-radius: 5px;
}

.reset-pwd-btn {
    width: 100%;
    padding: 13px;
    background-color: #242b5e; /* Bouton avec fond bleu foncé */
    color: white; /* Texte en blanc */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 30px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); /* Ombre grise */
    font-size: 14px;
}

.reset-pwd-btn:hover {
    background-color: #000f52; /* Bleu légèrement plus foncé au survol */
}

.reset-pwd-error-msg,
.reset-pwd-success-msg {
    margin-top: 10px;
}


.password-input-container1 {
    display: flex;
    align-items: center;
}

.toggle-password-visibility1 {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    margin-left: -40px;
    margin-top: 5px;
    color: #555;
}
