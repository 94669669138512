/* Base styles */
.carousel {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.carousel-inner {
  display: flex;
  transition: transform 1s ease;
}

.carousel-inner.transitioning {
  transition: transform 1s ease;
}

.carousel-item {
  min-width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.carousel-caption {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  text-align: center;
  color: white;
  width: 100%;
}

.carousel-caption h1 {
  font-size: 100px; /* Initial title size */
  font-weight: 100;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 0px;
  margin-top: -10px; 
}

.button-group {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  gap: 80px; /* Initial gap between buttons */
}

.custom-btn {
  background-color: #0d63a0;
  color: white;
  box-shadow: 2px 2px 5px grey;
  border: none;
  border-radius: 35px;
  padding: 13px 30px;
  font-size: 1.20rem;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-btn:hover {
  transform: scale(1.1);
  box-shadow: 4px 4px 10px grey;
}

.custom-btn:focus {
  outline: none;
}

.custom-btn:active {
  transform: scale(1.05);
  box-shadow: 2px 2px 5px grey;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
}

.carousel-control-icon {
  font-size: 2rem;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .carousel-caption h1 {
    margin-top: -200px;
    font-size: 5rem; /* Smaller title size */
  }

  .button-group {
    gap: 1.5rem; /* Reduced gap between buttons */
  }

  .carousel-control-icon {
    font-size: 2rem;
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none; /* Hide controls on medium screens */
  }
}

@media (max-width: 768px) {
  .carousel-caption h1 {
    font-size: 4rem; /* Further reduced title size */
  }

  .button-group {
    flex-direction: column; /* Stack buttons vertically */
    gap: 1rem; /* Reduced gap between buttons */
  }

  .custom-btn {
    background-color: #0d63a0;
    color: white;
    box-shadow: 2px 2px 5px grey;
    border: none;
    border-radius: 35px;
    font-size: 0.7rem;
    cursor: pointer;
    width: 25%; /* Make buttons take more space horizontally */
    margin: 0 auto; /* Center buttons */
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none; /* Hide controls on small screens */
  }
}

@media (max-width: 553px) {
  .carousel-caption h1 {
    font-size: 4rem; /* Further reduced title size */
  }

  .button-group {
    flex-direction: column; /* Stack buttons vertically */
    gap: 1rem; /* Reduced gap between buttons */
  }

  .custom-btn {
    background-color: #0d63a0;
    color: white;
    box-shadow: 2px 2px 5px grey;
    border: none;
    border-radius: 35px;
    font-size: 0.7rem;
    cursor: pointer;
    width: 30%; /* Make buttons take more space horizontally */
    margin: 0 auto; /* Center buttons */
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none; 
  }
}

@media (max-width: 480px) {
  .carousel-caption h1 {
    margin-top: -100px;
    font-size: 3rem; /* Smallest title size */
  }

  .button-group {
    gap: 1rem; /* Further reduced gap between buttons */
  }

  .custom-btn {
    background-color: #0d63a0;
    color: white;
    box-shadow: 2px 2px 5px grey;
    border: none;
    border-radius: 35px;
    font-size: 0.7rem;
    cursor: pointer;
    width: 40%; /* Make buttons full-width */
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none; 
  }
}
