@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
html {
  scroll-behavior: smooth;
}

.app {
  display: flex;
}

.admin-content {
  margin-left: 210px; 
  padding: 20px;
  width: calc(100% - 250px);
  background-color: #f5f7fa;
}
