.offres-container {
    display: flex;
    background-color: #f8f9fa;
    height: 100vh;
}

.offres-content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 250px; 
    margin-top: 60px;
}

.offres-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.add-offer-button {
    padding: 10px 20px;
    background-color: #848c94;
    font-size: 15px;
    border: 1px solid #848c94;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 200px;
}

.add-offer-button:hover {
    background-color: #a4acb5;
}

.search-bar {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-top: 20px;
    margin-left: 200px;
    margin-bottom: 20px;
}

.search-bar input {
    padding: 10px 15px;
    border: 2px solid #ced4da;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    outline: none;
}

.search-bar .search-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: -40px;
    padding: 0;
}

.search-bar .search-button svg {
    font-size: 16px;
    color: #5e5e5e;
}

.offres-table {
    width: 80%;
    border-collapse: separate; /* Change from collapse to separate to apply border-radius */
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0 auto; 
    border-spacing: 0; 
    overflow: hidden; /* Ensure border-radius is visible by hiding overflow */
}

.offres-table th:first-child {
    border-top-left-radius: 8px; /* Apply border-radius to the top-left corner */
}

.offres-table th:last-child {
    border-top-right-radius: 8px; /* Apply border-radius to the top-right corner */
}

.offres-table tr:last-child td:first-child {
    border-bottom-left-radius: 8px; /* Apply border-radius to the bottom-left corner */
}

.offres-table tr:last-child td:last-child {
    border-bottom-right-radius: 8px; /* Apply border-radius to the bottom-right corner */
}

.offres-table th, .offres-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #e9ecef;
}

.offres-table th {
    background-color: #c0c6cc;
    padding: 12px 15px;
    text-align: left;
    color: #333;
    font-weight: 600;
    font-size: 16px;
}

.offres-table th:nth-child(1) {
    width: 20%; 
}

.offres-table th:nth-child(2) {
    width: 45%; 
}

.offres-table th:nth-child(3), .offres-table td:nth-child(3) {
    width: 35%; 
}

.offres-table td:last-child {
    border-right: none; /* Remove border from the last column */
}

.offres-table tr:hover {
    background-color: #f1f1f1;
}
.offres-table tbody tr{
    border-bottom: 1px solid #e0e0e0;
}


.voir-plus-button {
    padding: 8px 12px;
    background-color: #848c94;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
    margin-left: 20px;
}

.voir-plus-button:hover {
    background-color: #a4acb5;
}

.delete-button {
    padding: 8px 12px;
    background-color: #ed4a4a;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 20px;
    transition: background-color 0.3s;
}

.delete-button:hover {
    background-color: #de3535;
}

.pagination1 {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin-top: 20px;
    margin-right: 10%;
}

.pagination1 li {
    margin: 0 5px;
    cursor: pointer;
}

.pagination1 li a {
    padding: 6px 12px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    color: #a4acb5;
    text-decoration: none;
}

.pagination1 li.active a {
    background-color: #a4acb5;
    color: #ffffff;
    border: 1px solid #a4acb5;
}

.pagination1 li a:hover {
    background-color: #f3f3f3;
    border-color: #bec3c8;
}
