.sociétés-section {
  background-color: #f5f5f5;
  padding: 0 200px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.section-title {
  margin-top: 40px;
  margin-bottom: 30px;
  color: #0d63a0;
  text-shadow: 1px 1px 2px grey;
  text-align: center;
  font-weight: 600;
  font-size: 2em; 
}

.sociétés-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
  margin-bottom: 40px;
}

.société-card {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  text-align: left;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s;
}

.société-card:hover {
  transform: scale(1.05);
}

.société-image {
  display: block;
}

.société-image.logo1 {
  width: 100px;
  height: 100px;
}

.société-image.afs {
  width: 120px;
  height: 80px;
}

.société-image.att {
  width: 110px;
  height: 105px;
}

.société-image.be {
  width: 110px;
  height: 100px;
}

.société-image.lrc {
  width: 140px;
  height: 95px;
}

.société-image.pm {
  width: 150px;
  height: 100px;
}

.société-image.sabureautel {
  margin-top: 10px;
  width: 200px;
  height: 100px;
}

.société-image.je {
  width: 145px;
  height: 120px;
}

.société-title {
  font-size: 1.2em;
  margin: 20px 0 10px;
  text-align: center;
  text-shadow: 1px 1px 2px grey;
  min-height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.société-description {
  font-size: 0.9em;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.société-link {
  color: green;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-top: auto;
  margin-bottom: 20px;
}

.société-link::after {
  content: '';
  height: 1px;
  background-color: green;
  position: absolute;
  width: 100%;
  bottom: -2px;
  left: 0;
}

.société-link svg {
  margin-left: 5px;
}

/* Responsive styles */
@media (max-width: 1024px) {

  .société-card {
    width: 80%;
    margin-top: 10px;
  }
  
  .sociétés-section {
    padding: 0 100px;
  }

  .sociétés-container {
    grid-template-columns: repeat(2, 1fr);
  }

}

@media (max-width: 768px) {
  
  .société-card {
    width: 80%;
    margin-top: 10px;
  }

  .sociétés-section {
    padding: 0 50px;
  }

  .sociétés-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .sociétés-section {
    padding: 0 20px;
  }

  .société-card {
    width: 80%;
    margin-top: 10px;
  }

  .société-title {
    font-size: 1em;
  }

  .société-description {
    font-size: 0.8em;
  }
}

@media (max-width: 390px) {

  .société-card {
    width: 80%;
  }
}
