/* Postulations.css */
.postulations-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: #f5f6fa;
}

.postulations-content {
    margin-left: 250px; /* Adjust this value based on the width of your sidebar */
    padding: 20px;
    padding-top: 40px; /* Added space above the table */
    width: calc(100% - 250px); /* Adjust this value to ensure proper spacing */
    overflow-y: auto;
}

.postulations-content h1 {
    margin-top: 60px;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
}

.postulations-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-top: 30px; /* Added margin to create space above the table */
}

.postulations-table thead {
    background-color: #d1d8e0; /* Light blue background */
}

.postulations-table thead th {
    padding: 12px 15px;
    text-align: left;
    color: #333;
    font-weight: 600;
    font-size: 16px;
}

.postulations-table tbody tr {
    border-bottom: 1px solid #e0e0e0;
}

.postulations-table tbody tr:last-child {
    border-bottom: none;
}

.postulations-table tbody td {
    padding: 12px 15px;
    text-align: left;
    font-size: 14px;
    color: #555;
}

.postulations-table tbody td a {
    color: #3498db;
    text-decoration: none;
    font-weight: 500;
}

.postulations-table tbody td a:hover {
    text-decoration: underline;
}

.postulations-table tbody td button {
    background-color: #ed4a4a;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.postulations-table tbody td button:hover {
    background-color: #de3535;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin-top: 30px;
    margin-right: 0%;
}

.pagination li {
    margin: 0 5px;
    cursor: pointer;
}

.pagination li a {
    padding: 6px 12px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    color: #a4acb5;
    text-decoration: none;
}

.pagination li.active a {
    background-color: #a4acb5;
    color: #ffffff;
    border: 1px solid #a4acb5;
}

.pagination li a:hover {
    background-color: #f3f3f3;
    border-color: #bec3c8;
}
