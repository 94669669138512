/* Base styles */
.apropos-container {
  margin: 50px;
  margin-top: 70px;
}

.apropos-title {
  color: #0d63a0;
  text-shadow: 1px 1px 2px grey;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  font-weight: 600;
}

.apropos-content {
  margin-top: 50px;
  display: flex;
  align-items: flex-start; /* Ensure content is aligned at the start */
}

.apropos-images img {
  width: 550px;
  height: 450px;
  margin-right: 200px; /* Further reduce the right margin */
  margin-left: 40px;
}

.apropos-text {
  max-width: 600px;
}

.apropos-text p {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: justify;
}

.apropos-list {
  list-style: none;
  padding: 0;
}

.apropos-list li {
  margin-left: -10px;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
  display: flex;
  align-items: baseline;
  text-align: justify;
}

.apropos-list li svg {
  margin-right: 10px;
  color: red;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .apropos-title {
    font-size: 1.8em; /* Slightly smaller title */
  }

  .apropos-content {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center;
  }

  .apropos-images img {
    width: 100%; /* Make image responsive */
    height: auto;
    margin: 0; /* Reset margins */
  }

  .apropos-text {
    max-width: 100%;
    margin-top: 20px;
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  .apropos-title {
    font-size: 1.6em; /* Further reduced title size */
  }

  .apropos-content {
    margin-top: 30px; /* Adjust top margin */
  }

  .apropos-text p, .apropos-list li {
    font-size: 14px; /* Smaller font size for text */
    margin-bottom: 20px; /* Adjust margin between list items */
  }

  .apropos-list li svg {
    font-size: 1.2em; /* Smaller icon size */
  }
}

@media (max-width: 480px) {
  .apropos-title {
    font-size: 1.4em; /* Smallest title size */
  }

  .apropos-text p, .apropos-list li {
    font-size: 12px; /* Smallest font size for text */
    margin-bottom: 15px; /* Adjust margin between list items */
    margin-left: -20px;
    margin-right: -20px;
  }

  .apropos-list li svg {
    font-size: 1.2em; /* Smallest icon size */
  }
}

@media (max-width: 390px) {
  .apropos-title {
    font-size: 1.4em; /* Smallest title size */
  }

  .apropos-text p, .apropos-list li {
    font-size: 12px;
    margin-bottom: 15px; 
    margin-left: -20px;
    margin-right: -20px;
  }

  .apropos-list li svg {
    font-size: 1.2em; 
  }
}