

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #f0f4ff;
}

.login-box {
    background-color: #ffffff;
    border-radius: 20px 0 0 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 100%;
    max-width: 750px;
    text-align: center;
    margin-left: 40px;
}

.login-box h2 {
    font-size: 40px;
    color: #242b5e;
    margin-bottom: 70px;
    margin-top: 58px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form input {
    width: 75%;
    padding: 12px 15px;
    margin-bottom: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 3px gray;
    font-size: 16px;
    outline: none;
}

.login-form input::placeholder {
    color: #ccc;
}

.login-button {
    width: 75%;
    padding: 12px 15px;
    background-color: #242b5e;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    margin-top: -10px;
}

.login-button .arrow {
    margin-left: 20px;
    margin-top: -2px;
    font-size: 24px;
    transition: margin-left 0.3s ease;
}

.login-button:hover {
    background-color: #000f52;
}

.login-button:hover .arrow {
    margin-left: 15px;
}

.login-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dbe2f4;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-right: 40px;
}

.login-right img {
    height: 600px;
    width: 600px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    margin-left: 20px;
}

.forgot-password {
    margin-top: 10px; 
    margin-bottom: 48px;
}

.forgot-password a{
    color: #000f52;
}


.password-input-container {
    display: flex;
    align-items: center;
    width: 79%;  /* S'assurer que le conteneur d'input a la même largeur que les autres inputs */
    margin-bottom: 40px;
    position: relative;
}

.toggle-password-visibility {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    position: absolute;
    right: 15px;  /* Positionner l'icône à droite de l'input */
    margin-top: -30px;
    color: #555;
}

.login-form input[type="password"],
.login-form input[type="text"] {
    width: 100%;  /* S'assurer que l'input prend toute la largeur disponible dans son conteneur */
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
}
