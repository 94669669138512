.admin-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}
  
.admin-content {
    display: flex;
    flex: 1;
}
  
.ajouter-admin-container {
    width: 100%;
    max-width: 500px;
    margin: auto;
    margin-top: 100px;
    padding: 126px 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
}
  
.ajouter-admin-container h6 {
    text-align: center;
    color: #848c94;
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: -60px;
    text-shadow: 2px 2px 4px rgba(143, 140, 140, 0.2); /* Ombre grise au texte */
}
  
.message {
    color: #848c94;
    text-align: center;
    margin-bottom: 20px;
}
  
.form-group {
    margin-bottom: 15px;
}
  
.form-group label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}
  
.form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
}
  
.submit-button-ajout {
    display: block; /* Important pour que margin auto fonctionne */
    width: 100%;
    padding: 10px;
    background-color: #a4acb5;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 20px auto; /* Centrer le bouton horizontalement */
    margin-bottom: -60px;
}
  
.submit-button-ajout:hover {
    background-color: #848c94;
    width: 100%;
}
