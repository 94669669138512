.newsletter-container {
    display: flex;
    height: 100vh;
    background-color: #f8f9fa;
    
}

.newsletter-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
}

.newsletter-title {
    font-size: 26px;
    font-weight: 600;
    margin-top: 90px;
    margin-left: 305px;
    margin-bottom: 20px;
    color: #333;
}

.table-container {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 75%;
    margin-left: 300px;
    margin-top: 30px;
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.styled-table thead tr {
    background-color: #f2f2f2;
    color: #333;
    text-align: left;
    font-weight: bold;
}

.styled-table th, .styled-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
}

.styled-table tbody tr:hover {
    background-color: #f1f1f1;
}

.email-link {
    color: #2c3e50;
    text-decoration: none;
}

.email-link:hover {
    text-decoration: underline;
}

.delete-button-new {
    padding: 8px 12px;
    background-color: #ed4a4a;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    width: 35%;
    transition: background-color 0.3s;
}

.delete-button-new:hover {
    background-color: #de3535;
}
