.nos-offres {
  text-align: center;
  margin: 100px;
}

.nos-offres-title {
  font-size: 2em;
  margin-bottom: 40px;
  margin-top: -20px;
  color: #0d63a0;
  text-shadow: 1px 1px 2px grey;
  text-align: center;
  font-weight: 600;
}

.offer-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.offer-content {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  padding: 20px;
  flex: 1;
  border-radius: 20px 0 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.offer-title {
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 45px;
  margin-top: 102px;
  text-shadow: 1px 1px 2px grey;
}

.offer-description {
  text-align: center;
  font-size: 1em;
  margin-bottom: 50px;
  text-shadow: 1px 1px 1px grey;
}

.offer-button {
  background-color: #0d63a0;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.3s ease;
  align-self: center;
  margin-bottom: 88px;
}

.offer-button:hover {
  transform: scale(1.1);
  box-shadow: 4px 4px 10px grey;
  background-color: #2980b9;
}

.offer-button:focus {
  outline: none;
}

.offer-button:active {
  transform: scale(1.05);
  box-shadow: 2px 2px 5px grey;
}

.offer-image-container {
  flex: 1;
  display: flex;
  align-items: center;
}

.offer-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 20px 20px 0;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .nos-offres {
    margin: 50px;
  }

  .offer-card {
    flex-direction: column;
  }

  .offer-content, .offer-image-container {
    border-radius: 20px;
  }

  .offer-content {
    order: 2;
    border-radius: 0 0 20px 20px;
  }

  .offer-image-container {
    order: 1;
    border-radius: 20px 20px 0 0;
  }
  
}

@media (max-width: 768px) {
  .nos-offres {
    margin: 30px;
  }

  .nos-offres-title {
    font-size: 1.8em;
  }

  .offer-title {
    font-size: 1.2em;
    margin-bottom: 30px;
  }

  .offer-description {
    font-size: 0.9em;
    margin-bottom: 30px;
  }

  .offer-button {
    font-size: 18px;
    padding: 8px 16px;
    margin-bottom: 60px;
  }
}

@media (max-width: 480px) {
  .nos-offres {
    margin: 20px;
  }

  .nos-offres-title {
    font-size: 1.6em;
  }

  .offer-title {
    font-size: 1em;
    margin-bottom: 20px;
  }

  .offer-description {
    font-size: 0.8em;
    margin-bottom: 20px;
  }

  .offer-button {
    font-size: 16px;
    padding: 6px 12px;
    margin-bottom: 40px;
  }
}
