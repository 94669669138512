.contact-container {
  padding: 0 100px;
}

.contact-title {
  text-shadow: 1px 1px 2px grey;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 2em;
  color: #0d63a0;
  font-weight: 600;
}

.contact-form-container {
  display: flex;
  background-color: #F9F9F9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

.contact-form-left, .contact-form-right {
  flex: 1;
  padding: 55px;
}

.contact-form-left-title1 {
  color: black;
  font-size: 1.5em;
  margin-bottom: 50px;
  text-align: left;
}

.contact-form-left-text p {
  color: black;
  text-align: left;
  margin-bottom: 50px;
  line-height: 2;
  font-size: 20px;
  margin-left: 20px;
  margin-right: 15px;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #F9F9F9;
  border-radius: 15px;
}

.submit-button-contact {
  display: block;
  margin-left: auto;
  padding: 10px 20px;
  background-color: #0d63a0;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  box-shadow: 2px 2px 5px grey;
  font-size: 1.1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.submit-button-contact:hover {
  transform: scale(1.1);
  box-shadow: 4px 4px 10px grey;
}

.submit-button-contact:focus {
  outline: none;
}

.submit-button-contact:active {
  transform: scale(1.05);
  box-shadow: 2px 2px 5px grey;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .contact-container {
    padding: 0 50px;
  }

  .contact-form-container {
    flex-direction: column;
  }

  .contact-form-left, .contact-form-right {
    padding: 30px;
  }

  .contact-title {
    font-size: 1.8em;
  }

  .contact-form-left-title1 {
    font-size: 1.3em;
    margin-bottom: 30px;
  }

  .contact-form-left-text p {
    font-size: 18px;
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .contact-container {
    padding: 0 20px;
  }

  .contact-title {
    font-size: 1.6em;
  }

  .contact-form-left-title1 {
    font-size: 1.2em;
    margin-bottom: 20px;
  }

  .contact-form-left-text p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .contact-container {
    padding: 0 10px;
  }

  .contact-title {
    font-size: 1.4em;
  }

  .contact-form-left, .contact-form-right {
    padding: 20px;
  }

  .contact-form-left-title1 {
    font-size: 1.1em;
    margin-bottom: 15px;
  }

  .contact-form-left-text p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .contact-form-left {
    order: 1;
  }

  .contact-form-right {
    order: 2;
  }
}
