.admin-sidebar {
    background-color: #2c3e50;
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 60px; 
    color: #fff;
    z-index: 1000;
}

.admin-sidebar__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.admin-sidebar__item {
    margin-top: 10px;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #ecf0f1;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-sidebar__item:hover {
    background-color: #34495e;
}

.admin-sidebar__item .right-icon {
    margin-left: auto;
    font-size: 0.8rem;
    transition: transform 0.3s ease;
}

.admin-sidebar__item .right-icon.rotated {
    transform: rotate(180deg);
}

.admin-sidebar__item svg {
    margin-right: 20px;
    margin-left: 10px;
    font-size: 1.4rem;
}
