.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.job-application-container {
    text-align: center;
    padding: 20px;
    flex: 1;
}

.main-title {
    margin-top: 90px;
    color: #0d63a0;
    text-shadow: 1px 1px 2px grey;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 10px;
}

.sub-title {
    font-size: 16px;
    color: #6c757d;
    margin-bottom: 40px;
}

.form-card {
    background-color: #f8f9fa;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 800px; /* Augmentez la largeur ici */
    margin: 0 auto;
    border: 1px solid #ced4da; /* Ajoute une bordure grise */
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    margin-bottom: 5px;
    text-align: left;
    font-weight: bold; /* Ajoute du gras aux labels */
}

.form-group input,
.form-group select {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Ajoute un léger ombrage à l'intérieur des champs */
    margin-bottom: 10px; /* Ajoute un espace entre les champs */
}

.form-group input[type="file"] {
    padding: 5px;
}

.button-group {
    display: flex;
    justify-content: center;
}

.submit-button-app {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #0d63a0;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
    width: 50%;
}

.submit-button-app:hover {
    transform: scale(1.1); /* Agrandissement */
    box-shadow: 4px 4px 10px grey; /* Ombre accrue pour un effet de relief */
    background-color: #2980b9;
}
