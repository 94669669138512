.dashboard-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .dashboard-content {
    display: flex;
    margin-left: 250px; /* Adjust based on the width of your sidebar */
    padding: 20px;
    background-color: #f4f4f4;
    min-height: calc(100vh - 40px); /* Adjust based on the height of your navbar */
    overflow-y: auto;
  }
  
  .dashboard-container {
    width: 100%;
  }
  
  .congrats-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9faff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    padding: 40px;
    margin: 0px 90px;
    margin-top: 100px;
  }
  
  .congrats-text h5 {
    font-size: 24px;
    color: #2C3E50;
    margin: 0;
    font-size: 35px;
    margin-top: 0px;
    margin-bottom: 40px;
  }
  
  .congrats-text p {
    color: #6b7280;
    margin: 10px 0;
    font-size: 19px;
    margin-bottom: 40px;
  }
  
  .view-badges-btn {
    background-color: #d1d9ff;
    color: #2C3E50;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s ease;
  }
  
  .view-badges-btn:hover {
    background-color: #f0ecec;
  }
  
  .congrats-image img {
    width: 250px;
    height: 200px;
    border-radius: 12px;
  }
  
