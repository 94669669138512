/* Add the grid layout for the dashboard container */
.dashboard-container-contact {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 80px auto;
    height: 100vh;
    background-color: #f4f4f4;
}

/* Styles for the navbar */
.navbar {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    /* your navbar styles */
}

/* Styles for the sidebar */
.sidebar {
    grid-column: 1 / 2;
    grid-row: 2 / -1;
    /* your sidebar styles */
}

/* Styles for the messages container */
.messages-container {
    margin-top: 80px;
    margin-left: 300px;
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 70px; /* Adds space between the cards */
}

/* Styles for the message cards */
.message-card {
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    width: 280px; /* Makes the card take full width of the grid column */
    position: relative;
}

.message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.message-header h3 {
    margin: 0;
    color: #2C3E50;
    margin-left: 10px;
}

.close-btn {
    cursor: pointer;
    font-size: 25px;
    color: #000;
}

.message-card p {
    margin: 15px 0;
    margin-left: 10px;
    margin-right: 10px;
    text-align: justify;
}

.delete-btn-mes {
    background-color: #ed4a4a;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .delete-btn-mes:hover {
    background-color: #de3535;
  }
  