/* ResetPasswordRequest.css */
.page-container-pass {
    background-color: #eaf7f9; /* Light blue background */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    margin: 0;
}

.request-pwd-container {
    max-width: 500px; /* Enlarged from 400px to 500px */
    width: 100%;
    padding: 50px 40px; /* Increased padding for better spacing */
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Gray shadow */
    text-align: center; /* Center content inside the card */
}

.request-pwd-container h2{
    font-size: 30px;
    color: #242b5e;
}

.request-pwd-form input {
    width: calc(100% - 20px); /* Adjust width to allow for padding */
    padding: 10px;
    margin: 20px 0px; /* Added horizontal margin for centering */
    border: 1px solid #ccc;
    border-radius: 5px;
    
}

.request-pwd-btn {
    width: 100%;
    padding: 13px;
    background-color: #242b5e; /* Button with light blue background */
    color: white; /* Black text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 30px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); /* Gray shadow */
    font-size: 14px;
}

.request-pwd-btn:hover {
    background-color: #000f52; /* Slightly darker blue on hover */
}

.request-pwd-error-msg,
.request-pwd-success-msg {
    margin-top: 10px;
}
