.admin-navbar {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: calc(100% - 250px);
    top: 0;
    left: 250px;
    z-index: 1000;
}

.admin-navbar__title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #34495e;
    margin-left: 20px;
}

.admin-navbar__profile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-right: 60px;
}

.admin-navbar__profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    
}

.admin-navbar__profile-name {
    font-size: 15px;
    font-weight: 500;
    color: #34495e;
    margin-right: 3px;
}

.admin-navbar__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    width: 200px;
    z-index: 1000;
}

.admin-navbar__dropdown-item {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
}

.admin-navbar__dropdown-item i {
    margin-right: 10px;
}

.admin-navbar__dropdown-item:hover {
    background-color: #f0f0f0;
}

.admin-navbar__dropdown-item span {
    margin-left: 10px;
}
