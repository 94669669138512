/* Base styles */
.footer {
  display: flex;
  flex-direction: column; /* Column layout by default */
  align-items: center;
  padding: 20px;
  background-color: #F9F9F9;
}

.footer-left, .footer-middle {
  width: 100%;
  margin-top: 20px;
}

.footer-left p, .footer-middle p, h3 {
  margin: 20px 20px; 
  text-align: justify;
}

.footer-middle form {
  display: flex;
  flex-direction: column; /* Column layout by default */
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.footer-middle input {
  width: 80%; /* Full width by default */
  padding: 10px;
  margin-bottom: 10px; /* Space between input and button */
  border: 1px solid #F9F9F9;
  border-radius: 10px;
}

.footer-middle button {
  width: 80%; /* Full width by default */
  padding: 10px;
  border: none;
  border-radius: 40px;
  background-color: #1072B2;
  color: white;
  cursor: pointer;
}

.footer-middle button:hover {
  background-color: #0e6aa7;
}
.footer-middle h3 {
  text-align: center;
}

.footer-right h3 {
  margin-left: 0;
  text-align: center;
}

.footer-right ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.footer-right li {
  margin-bottom: 20px;
}

.footer-right a {
  text-decoration: none;
  color: black;
}

.footer-logo {
  width: 100px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.map-container {
  width: 100%;
  margin: 20px 0;
}

.footer-social {
  display: flex;
  flex-direction: column; /* Column layout by default */
  align-items: center;
  padding: 20px;
  background-color: #E0E0E0;
}

.footer-social .social-icons {
  display: flex;
  gap: 15px;
  margin-bottom: 10px; /* Space between icons and text */
}

.icon {
  color: red;
  margin-right: 5px;
}

.footer-social .social-icons {
  font-size: 24px;
  color: black;
}

.footer-social .footer-text {
  text-align: center;
  width: 100%; /* Ensure it takes full width */
}

.footer-text {
  text-align: center;
}

/* Media queries for larger screens */
@media (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-left, .footer-middle, .footer-right {
    width: 30%;
    text-align: left;
  }

  .footer-middle form {
    flex-direction: row;
  }

  .footer-middle input {
    width: 70%;
    margin-right: 10px;
  }

  .footer-middle button {
    width: 30%;
  }

  .footer-social {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (min-width: 330px) {
  .footer-middle button {
    width: 30%;
    font-size: 12px;
  }
}