/* Base styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
}

.navbar-logo {
  padding: 5px 20px 0px 10px;
}

.navbar-logo img {
  height: 70px;
}

.navbar-links {
  display: flex;
  gap: 100px;
}

.navbar-links a {
  text-decoration: none;
  color: black;
  font-weight: 400;
  font-size: 18px;
}

.navbar-links a:hover {
  color: red;
}

.navbar-icon {
  font-size: 26px;
  cursor: pointer;
  margin-right: 50px;
  margin-top: 7px;
}

/* Sidebar styles */
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 340px;
  height: 100%;
  background-color: white;
  background-image: url('../assets/images/logo3.jpg'); /* Add background image */
  background-size: 80%; /* Cover the entire sidebar */
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  box-shadow: -2px 0 5px rgba(0,0,0,0.5);
  z-index: 2000;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Rest of your CSS remains the same */


.sidebar-close {
  align-self: flex-end;
  cursor: pointer;
  font-size: 24px;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sidebar-content h1{
  font-size: 2.3em;
  color: #0d63a0;
  text-shadow: 1px 1px 2px grey;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: 5px;
}


.sidebar-content p {
  margin: 20px 0; /* Adding margin between paragraphs */
}

.contact-info, .office-address {
  margin-top: 20px;
  width: 100%;
  align-items: center;
}

.contact-info h4, .office-address h4 {
  margin-bottom: 15px; /* Adding margin below headings */
  margin-top: 10px;
  font-size: 22px;
}

.contact-info p, .office-address p {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin: 20px 0; /* Adding margin between lines */
}

.sidebar-links {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-links a {
  text-decoration: none;
  color: black;
  font-weight: 400;
  font-size: 18px;
  margin: 10px 0;
}

.sidebar-links a:hover {
  color: red;
}

.sidebar .spacer {
  height: 50px; /* Space at the bottom of the sidebar */
}

/* Responsive styles */
@media (max-width: 1024px) {
  .navbar-links {
    display: none;
  }

  .navbar-logo {
    padding: 0px 20px 0px 10px;
  }

  .navbar-logo img {
    height: 50px;
    margin-bottom: -5px;
  }
}

@media (max-width: 300px) {
  .navbar {
    padding: 5px 10px;
  }

  .navbar-logo {
    padding: 5px 20px 0px 10px;
  }

  .navbar-logo img {
    height: 50px;
  }

  .navbar-icon {
    margin-right: 30px;
  }

  .sidebar {
    width: 250px;
  }

  .sidebar-logo {
    width: 80px;
  }

  .sidebar-content {
    padding: 10px;
  }
}
