.job-listings {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content-container {
  flex: 1;
  padding: 20px;
}

.main-content {
  text-align: center;
}

.page-title {
  margin-top: 90px;
  margin-bottom: 40px;
  color: #0d63a0;
  text-shadow: 1px 1px 2px grey;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  font-weight: 600;
}

.listings-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.listing-card {
  background-color: #fcfcfc;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 50px;
  width: 380px; 
  margin: 10px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.listing-title {
  margin-top: -20px;
  color: #0d63a0;
  text-shadow: 1px 1px 2px grey;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  font-weight: 500;
}

.listing-details {
  width: 100%;
  text-align: justify;
  margin-bottom: 20px;
}

.listing-details p {
  margin: 10px 0; /* Space between paragraphs */
}

.listing-details p strong {
  display: block; /* Make each <strong> start on a new line */
  margin-bottom: 5px; /* Space between the <strong> and the following text */
}

.apply-btn {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #0d63a0;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
  align-self: center;
  margin-bottom: 0px;
}

.apply-btn:hover {
  transform: scale(1.1); /* Agrandissement */
  box-shadow: 4px 4px 10px grey; /* Ombre accrue pour un effet de relief */
  background-color: #2980b9;
}

nav {
  margin: 0;
  padding: 0;
}

footer {
  margin: 0;
  padding: 0;
  margin-top: 20px;
}