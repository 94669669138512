.societe-detail7__content {
  padding: 30px; /* Reduced padding for smaller screens */
}

.societe-detail7__content h1 {
  color: #0d63a0;
  text-shadow: 1px 1px 2px grey;
  text-align: center;
  margin-top: 80px; /* Reduced top margin for smaller screens */
  margin-bottom: 20px; /* Reduced bottom margin for smaller screens */
  font-size: 2em; /* Reduced font size for smaller screens */
  font-weight: 600;
}

.societe-detail7__wrapper {
  display: flex;
  flex-direction: column; /* Column layout by default */
  align-items: center;
  margin-bottom: 20px; /* Reduced margin for smaller screens */
}

.societe-detail7__logo-container {
  flex: 1; /* Equal flex to share space */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px; /* Space between logo and text */
}

.societe-detail7__logo-container img {
  height: 180px; /* Increased height for larger screens */
    width: 300px; /* Reduced width for smaller screens */
}

.societe-detail7__text-container {
  flex: 1; /* Equal flex to share space */
  padding: 5px 20px; /* Padding around the text */
  text-align: justify;
  margin-top: 10px;
}

.societe-detail7__text-container p {
  word-wrap: break-word;
  margin: 0; /* Remove default margins */
  margin-left: 10px;
  margin-right: 10px;
}

/* New Section for Services */
.societe-detail7__services {
  text-align: center;
  margin-top: 20px; /* Reduced margin for smaller screens */
}

.societe-detail7__services h1 {
  color: #0d63a0;
  text-shadow: 1px 1px 2px grey;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px; /* Reduced margin for smaller screens */
  font-size: 1.9em; /* Reduced font size for smaller screens */
  font-weight: 600;
}

.societe-detail7__services-cards {
  display: flex;
  flex-direction: column; /* Column layout by default */
  align-items: center;
  gap: 20px;
}

.societe-detail7__service-card {
  background: #F9F9F9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px; /* Fixed width for all cards */
  height: 280px; /* Fixed height for all cards */
  text-align: center; /* Center text by default */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Ensures even spacing inside */
}

.societe-detail7__service-card svg {
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
}

.societe-detail7__service-card h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.societe-detail7__service-card p {
  font-size: 0.9em;
  line-height: 1.5em;
  text-align: center;
  margin: 0; /* Remove default margins */
  overflow: hidden; /* Ensures text does not overflow */
  text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
}

/* Media queries for larger screens */
@media (min-width: 1024px) {
  .societe-detail7__content {
    padding: 50px;
  }

  .societe-detail7__content h1 {
    margin-top: 60px;
    margin-bottom: 30px;
    font-size: 2em;
  }

  .societe-detail7__wrapper {
    flex-direction: row; /* Row layout for larger screens */
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
  }

  .societe-detail7__logo-container {
    flex: 1; /* Equal flex to share space */
    margin-bottom: 0;
  }

  .societe-detail7__logo-container img {
    height: 180px; /* Increased height for larger screens */
    width: 340px; /* Increased width for larger screens */
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .societe-detail7__text-container {
    flex: 1; /* Equal flex to share space */
    padding: 20px; /* Padding around the text */
  }

  .societe-detail7__services {
    margin-top: 40px;
  }

  .societe-detail7__services h1 {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 2em;
  }

  .societe-detail7__services-cards {
    flex-direction: row; /* Row layout for larger screens */
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .societe-detail7__service-card {
    width: calc(33.333% - 40px); /* Three cards per row with gaps */
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .societe-detail7__services-cards {
    flex-direction: row; /* Row layout for medium screens */
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .societe-detail7__service-card {
    width: calc(50% - 40px); /* Two cards per row with gaps */
  }

  .societe-detail7__logo-container img {
    height: 180px; /* Increased height for larger screens */
    width: 340px; /* Increased width for larger screens */
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .societe-detail7__services-cards {
    flex-direction: column; /* Column layout for smaller screens */
    align-items: center;
    gap: 20px;
  }

  .societe-detail7__service-card {
    width: calc(100% - 40px); /* One card per row */
    height: 250px;
  }

  .societe-detail7__logo-container img {
    height: 150px; /* Increased height for larger screens */
    width: 300px; /* Increased width for larger screens */
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
